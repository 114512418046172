<template>
  <div class="p-grid">
    <div class="p-col-12">
      <DataTable
        :value="selectedEngineerData"
        ref="reportEngineerTable"
        :paginator="true"
        dataKey="id"
        class="p-datatable-customers p-datatable-striped"
        :rows="10"
        :rowHover="true"
        :autoLayout="true"
        :loading="isLoading"
        selectionMode="single"
        @row-click="goToTask"
      >
        <template #header>
          <div class="p-d-flex p-jc-between p-flex-column">
            <div class="table-header p-col-12 p-mb-4">
              {{ tableTitle }}
            </div>

            <div class="p-d-flex p-flex-row p-jc-between">
              <div class="filters p-d-flex p-flex-row">
                <RangeDatePicker v-model:range="filterDate" />
              </div>

              <div class="buttons">
                <Button
                  @click="loadFilteredReport"
                  class="p-button-success p-mr-2"
                >
                  Сформировать отчет
                </Button>
                <Button
                  @click="downloadReport"
                  class="p-button-success p-mr-2"
                  :disabled="!urlWithFilters"
                >
                  Скачать отчет
                </Button>
                <Button class="p-button-danger" @click="clearFilters">
                  Очистить фильтры
                </Button>
              </div>
            </div>
          </div>
        </template>
        <template #empty>Заявки не найдены</template>
        <template #loading>Загружаем заявки...</template>

        <Column
          field="number"
          header="Номер"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.number }}
          </template>
        </Column>

        <Column
          field="companyName"
          header="Клиент"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.companyName }}
          </template>
        </Column>

        <Column
          field="taskWorkType"
          header="Вид ремонта"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.taskWorkType.name }}
          </template>
        </Column>

        <Column
          field="companyDistrict"
          header="Район"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.companyDistrict }}
          </template>
        </Column>

        <Column
          field="formattedTime"
          header="Отработанное время"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.formattedTime }}
          </template>
        </Column>

        <Column
          field="distance"
          header="Пройденное расстояние"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.distance }} км.
          </template>
        </Column>

        <Column
          field="takeToWorkTime"
          header="Время взятия в работу (с назначения до взятия в работу)"
          :sortable="true"
          :show-filter-menu="false"
        >
        </Column>

        <Column
          field="workingTime"
          header="Время работы по заказу (с взятия в работу до закрытия инженером)"
          :sortable="true"
          :showFilterMenu="false"
        >
          <template #body="slotProps">
            {{ slotProps.data.workingTime }}
          </template>
        </Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import { environment } from "../../../config";
import dayjs from "dayjs";
import { Calendar, DatePicker } from "v-calendar";
import RangeDatePicker from "../../operator/components/RangeDatePicker";

export default {
  components: {
    RangeDatePicker,
    Calendar,
    DatePicker,
  },
  props: ["id", "dateStart", "dateEnd"],
  name: "AboutMe",
  data() {
    return {
      filterDate: {
        start: new Date(this.dateStart),
        end: new Date(this.dateEnd),
      },
      selectedEngineerData: null,
      isLoading: false,
      selectedEngineer: null,
      tableTitle: "Отчет по инженеру",
      urlWithFilters: null,
    };
  },
  async mounted() {
    let tmpId = parseInt(this.id);
    if (this.allEngineers.length === 0) {
      await this.$store.dispatch("myprofiles/loadOnlyEngineers");
    }
    this.selectedEngineer = this.allEngineers.filter((e) => {
      return e.id === tmpId;
    })[0].id;
    this.loadFilteredReport();
  },
  computed: {
    allEngineers() {
      return this.$store.getters["myprofiles/engineers"];
    },
    workTypeList() {
      return this.$store.getters["data/workTypes"];
    },
  },
  methods: {
    getDaysFromMinutes(time) {
      return `${Math.floor(time / 60 / 24)} д. ${
        Math.floor(time / 60) % 24
      } ч. ${time % 60} мин.`;
    },
    goToTask(event) {
      this.$router.push(`/task/${event.data.id}`);
    },
    getHoursFromSeconds(time) {
      if (time !== 0) {
        time = Math.floor(time / 60);
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        return `${hours} ч. ${minutes} мин.`;
      } else {
        return `0 ч. 0 мин.`;
      }
    },

    getHoursFromMinutes(time) {
      if (time !== 0) {
        let hours = Math.floor(time / 60);
        let minutes = time % 60;
        return `${hours} ч. ${minutes} мин.`;
      } else {
        return `0 ч. 0 мин.`;
      }
    },
    getNameByType(workType) {
      if (!this.workTypeList) return;
      return this.workTypeList.filter((wt) => {
        return wt.value === workType;
      })[0];
    },
    createTableDataModel(data) {
      return data.map((t) => {
        return {
          ...t,
          companyName: t.company.officialName,
          taskWorkType: this.getNameByType(t?.workType),
          formattedTime: this.getHoursFromMinutes(t.time),
          companyDistrict: t.company.district,
          workingTime: this.getHoursFromSeconds(t.workingTime),
          takeToWorkTime: this.getHoursFromSeconds(t.takeToWorkTime),
        };
      });
    },

    async loadFilteredReport() {
      this.isLoading = true;

      try {
        this.filterDate.start.setHours(0, 0, 0, 0);
        this.filterDate.end.setHours(23, 59, 59, 0);

        this.urlWithFilters =
          `?engineerId=${
            this.selectedEngineer
          }&dateStart=${this.filterDate.start.toISOString()}` +
          `&dateEnd=${this.filterDate.end.toISOString()}`;

        await this.$router.replace(this.urlWithFilters);

        const response = await fetch(
          `${environment.apiUrl}/reports/engineer${this.urlWithFilters}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
              "Content-Type": "application/json",
            },
          }
        );

        this.selectedEngineerData = await response.json();
        this.selectedEngineerData = this.createTableDataModel(
          this.selectedEngineerData
        );

        this.$toast.add({
          severity: "success",
          summary: "",
          detail: `Отчет сформирован`,
          life: 6000,
        });
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },
    async downloadReport() {
      this.isLoading = true;

      try {
        const response = await fetch(
          `${environment.apiUrl}/reports/engineer/excel${this.urlWithFilters}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.getters.token}`,
            },
          }
        );
        const url = window.URL.createObjectURL(await response.blob());
        const link = document.createElement("a");
        link.href = url;
        let filename = this.allEngineers.filter((e) => {
          return e.id === this.selectedEngineer;
        })[0];
        filename = `${filename.lastname} ${filename.firstname}`;
        link.setAttribute("download", `Отчет ${filename}.xls`);
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (err) {
        console.log(err);
      }

      this.isLoading = false;
    },

    clearFilters() {
      this.selectedEngineer = [];
      this.filterDate = {
        start: new Date(),
        end: new Date(),
      };
    },
  },
};
</script>

<style scoped>
.multiselect {
  width: 190px;
  color: black;
}
</style>
